import { NgwConnectorExtended } from './NgwConnectorExtended';

export type * from './interfaces';
export type * from './route/type';
export type * from './types/FeatureLayer';
export type * from './types/LegendItem';
export type * from './types/RequestItemsParamsMap';
export type * from './types/ResourceItem';
export type * from './types/ResourceStore';

export default NgwConnectorExtended;
